import React from "react";
import { Col, Container, Image, Nav, Navbar, Row } from "react-bootstrap";
import { Link } from "react-router-dom/dist";
import { useAuth } from "../AuthContext";
import Icon from "@mdi/react";
import { mdiCog, mdiExitRun, mdiExitToApp, mdiHome, mdiLocationExit, mdiLogout, mdiLogoutVariant, mdiProgressUpload, mdiViewGallery, mdiViewGalleryOutline } from "@mdi/js";

const BackendLayout = ({ children }) => {
    const { authInfo, logout, isLoggedIn } = useAuth();
    const handleLogout = () => {
        logout();
    };

    return (
        <div id="admin">
            <Navbar className="bg-body-tertiary">
                <Container fluid>
                    <Navbar.Brand as={Link} to="/admin">
                        Portfolio Manager
                    </Navbar.Brand>
                    <Navbar.Toggle />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link as={Link} to="/">
                                <Icon path={mdiHome} size={1} />
                                Frontend
                            </Nav.Link>
                            <Nav.Link as={Link} to="/admin/upload">
                                <Icon path={mdiProgressUpload} size={1} /> Upload
                            </Nav.Link>
                            <Nav.Link as={Link} to="/admin/settings">
                                <Icon path={mdiCog} size={1} /> Settings
                            </Nav.Link>
                            <Navbar.Text onClick={handleLogout}>
                                <Icon path={mdiLogout} size={1} /> Logout
                            </Navbar.Text>
                        </Nav>
                    </Navbar.Collapse>
                    <Navbar.Collapse className="justify-content-end">
                        {isLoggedIn() ? (
                            <Navbar.Text>
                                <span>{authInfo.name}</span> <Image src={`https://carlymarie.net/img/${authInfo._id}.jpg?_t=${authInfo.avatar}`} width={36} roundedCircle thumbnail />
                            </Navbar.Text>
                        ) : null}
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <Container fluid>
                <Row>
                    <Col md={12}>{children}</Col>
                </Row>
            </Container>
        </div>
    );
};

export default BackendLayout;
