import React, { useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiKey, mdiEmail } from "@mdi/js";
import { useAuth } from "../../AuthContext";
import useAPI from "../../helpers/apiHelper";

const LoginPage = (props) => {
    const { post } = useAPI();
    const { login } = useAuth();

    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const handleLogin = (e) => {
        e.preventDefault();
        post("/api/users/login", { email, password })
            .then((res) => {
                login(res.data);
                navigate("/admin");
            })
            .catch((err) => {
                const { status } = err.response;
                switch (status) {
                    case 404:
                        alert("Invalid Login Credentials");
                        break;
                    default:
                        break;
                }
            });
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        switch (name) {
            case "email":
                setEmail(value);
                break;
            case "password":
                setPassword(value);
                break;
            default:
                break;
        }
    };
    return (
        <div id="login">
            <div className="wrapper">
                <h1>Admin Login</h1>
                <Form onSubmit={handleLogin}>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1">
                            <Icon path={mdiEmail} size={1} color="white" />
                        </InputGroup.Text>
                        <Form.Control type="email" name="email" placeholder="Email" value={email} onChange={handleChange} />
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1">
                            <Icon path={mdiKey} size={1} color="white" />
                        </InputGroup.Text>
                        <Form.Control type="password" name="password" placeholder="Password" value={password} onChange={handleChange} />
                    </InputGroup>
                    <Button type="submit">Login</Button>
                </Form>
            </div>
        </div>
    );
};

LoginPage.propTypes = {};

export default LoginPage;
