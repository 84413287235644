import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Button, ProgressBar } from "react-bootstrap";
import useAPI from "../helpers/apiHelper";
import Icon from "@mdi/react";
import { mdiCloseCircle } from "@mdi/js";

const DropzoneComponent = (props) => {
    const { postWithProgress } = useAPI();
    const [files, setFiles] = useState([]);
    const [progress, setProgress] = useState([]);
    const [totalProgress, setTotalProgress] = useState(0);
    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            "image/*": []
        },
        onDrop: (acceptedFiles) => {
            setFiles((prev) => [...acceptedFiles.map((file, i) => Object.assign(file, { preview: URL.createObjectURL(file) })), ...prev]);
        }
    });

    const getTotalProgress = () => {
        if (progress.length === 0) {
            setTotalProgress(0);
            return;
        }

        const total = progress.reduce((acc, current) => acc + (current || 0), 0);
        const count = progress.filter(Boolean).length;
        const averageProgress = count > 0 ? total / count : 0;

        setTotalProgress(averageProgress);
    };

    useEffect(() => {
        getTotalProgress();
    }, [progress]);
    const upload = () => {
        let promises = [];
        files.forEach((file, i) => {
            const formData = new FormData();
            formData.append("photo", file);
            promises.push(
                postWithProgress("/api/photos", formData, (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setFiles((prevState) => prevState.map((obj) => (obj.name === file.name ? Object.assign({}, obj, { progress: percentCompleted }) : obj))); // This only updates 1 time and sticks at 38%
                    setProgress((prevProgress) => {
                        const newProgress = [...prevProgress];
                        newProgress[i] = percentCompleted;
                        return newProgress;
                    });
                    if (percentCompleted === 100) {
                        console.log("File upload completed:", file.name);
                        Promise.resolve(file, i);
                    }
                })
            );
        });
        Promise.all(promises).then((file, i) => {
            setFiles([]);
            setProgress([]);
            setTotalProgress(0);
        });
    };

    const removeFile = (file, i) => {
        setFiles(files.filter((_file) => _file !== file));
        let progresses = [...progress];
        progresses[i] = null;
        setProgress(progresses);
    };
    return (
        <>
            <div id="dropzone">
                <div {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps()} />
                    <p>Drag 'n' drop some files here, or click to select files</p>
                </div>
                <aside>
                    <div>{files.length > 0 ? <Button onClick={upload}>Upload</Button> : null}</div>
                    {files.map((file, i) => {
                        return (
                            <div className="img" style={{ backgroundImage: `url(${file.preview})` }} key={i}>
                                <Icon path={mdiCloseCircle} onClick={() => removeFile(file)} size={1} />
                                {progress[i] ? <ProgressBar now={progress[i]} label={`${progress[i]}%`} animated /> : null}
                            </div>
                        );
                    })}
                </aside>
            </div>
            <ProgressBar now={totalProgress} label={`${totalProgress.toFixed(0)}% Complete`} animated className="total-progress" />
        </>
    );
};

DropzoneComponent.propTypes = {};

export default DropzoneComponent;
