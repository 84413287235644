import React, { useEffect, useRef, useState } from "react";
import $ from "jquery";
import "jquery-ui-dist/jquery-ui";
import { mdiCloseCircle } from "@mdi/js/mdi";
import Icon from "@mdi/react";
import ImageLoader from "./components/ImageLoader";
import useAPI from "../../helpers/apiHelper";

const PhotosIndex = (props) => {
    const { get, remove, patch } = useAPI();
    const [images, setImages] = useState([]);
    const galleryRef = useRef(null);
    const getImages = () => {
        get("/api/photos").then((res) => {
            setImages(res.data);
        });
    };
    useEffect(() => {
        getImages();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        $(galleryRef.current)
            .sortable({
                tolerance: "pointer",
                stop: (e, u) => {
                    let pic = $(u.item);
                    $(".img").stop().not(pic).fadeTo(500, 1);
                    $(pic).css({ filter: "grayscale(1)" });
                },
                start: (e, u) => {
                    let pic = $(u.item);
                    $(".img").stop().not(pic).fadeTo(500, 0.5);
                    $(pic).css({ filter: "grayscale(0)" });
                },
                update: (event, ui) => {
                    const children = galleryRef.current.children;
                    Array.from(children).map((child, i) => {
                        const _id = child.id;
                        patch(`/api/photos/${_id}`, { order: i }).then(() => {
                            getImages();
                        });
                        return _id;
                    });
                }
            })
            .disableSelection();
    });
    const handleDelete = (image) => {
        console.log(image);
        remove(`/api/photos/${image._id}`)
            .then((res) => {
                getImages();
            })
            .catch((err) => {
                console.log(err);
            });
    };
    return (
        <div className="image-manager" ref={galleryRef}>
            {images.map((image, index) => (
                <ImageLoader image={image} key={image._id}>
                    <div className="actions">
                        <Icon path={mdiCloseCircle} width={24} data-id={image._id} onClick={() => handleDelete(image)} />
                    </div>
                </ImageLoader>
            ))}
        </div>
    );
};

export default PhotosIndex;
