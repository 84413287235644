import React, { useState } from "react";
import { Button, Col, Container, ListGroup, ListGroupItem, Row } from "react-bootstrap";
import ImageRotator from "./components/ImageRotator";
import ContactForm from "./components/ContactForm";

const HomePageIndex = (props) => {
    const [showContactForm, setShowContactForm] = useState(false);
    const vitals = [
        {
            name: "Measurements",
            value: "34D-24-36"
        },
        {
            name: "Height",
            value: "6ft 0in"
        },
        {
            name: "Weight",
            value: "128lbs"
        },
        {
            name: "Dress Size",
            value: "4"
        },
        {
            name: "Shoe Size",
            value: "10"
        }
    ];
    const handleBookingClick = () => {
        setShowContactForm(!showContactForm);
    };
    return (
        <section id="homepage">
            <Container>
                <Row>
                    <Col sm={12} md={2} className="left">
                        <div className="wrapper">
                            <div className="logo">Carly Marie</div>
                            <p>A Model of Versatility: With over two decades in the industry, mastering every facet of the runway, campaigns, and beyond.</p>
                            <div className="vitals">
                                <h2>Measurements</h2>
                                <ListGroup>
                                    {vitals.map((detail) => {
                                        return (
                                            <ListGroupItem>
                                                {detail.name}
                                                <abbr>{detail.value}</abbr>
                                            </ListGroupItem>
                                        );
                                    })}
                                </ListGroup>
                            </div>
                            <Button className="booking" onClick={handleBookingClick}>
                                Booking
                            </Button>
                        </div>
                    </Col>
                    <Col md={3} className="bio">
                        {showContactForm ? (
                            <ContactForm />
                        ) : (
                            <>
                                <h2>About Me</h2>
                                <p>I'm Carly Marie, an experienced model with a passion for diverse styles and creative projects. My portfolio spans the runway, high-fashion editorials, and impactful commercial campaigns, showcasing my adaptability and dedication to the craft.</p>

                                <p>Throughout my career, I've collaborated with skilled photographers, designers, and brands, focusing on precision and attention to detail to deliver compelling visuals. I take pride in my work, ensuring that each project reflects a commitment to excellence.</p>

                                <p>Beyond the spotlight, I maintain a balanced and healthy lifestyle to bring my best self to every endeavor. My approach is grounded in confidence, charisma, and a unique blend of styles, embodying beauty in its authentic form.</p>

                                <p>Join me on this journey as we explore the vast possibilities of visual storytelling. Let's create impactful and memorable work together.</p>

                                <p>Carly Marie</p>
                            </>
                        )}
                    </Col>
                    <Col xs={12} md={7} className="right">
                        <ImageRotator />
                    </Col>
                </Row>
            </Container>
            <div id="blur"></div>
        </section>
    );
};

export default HomePageIndex;
