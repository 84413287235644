import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Form } from "react-bootstrap";
import validator from "validator";

const ContactForm = (props) => {
    const [contact, setContact] = useState({});
    const [validity, setValidity] = useState({
        phone: false,
        name: false,
        email: false,
        message: false
    });
    const normalizeInput = (input) => {
        input = input.replace(/\D/g, "").substring(0, 10); //Strip everything but 1st 10 digits
        var size = input.length;
        if (size > 0) {
            input = "(" + input;
        }
        if (size > 3) {
            input = input.slice(0, 4) + ") " + input.slice(4);
        }
        if (size > 6) {
            input = input.slice(0, 9) + "-" + input.slice(9);
        }
        return input;
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        switch (name) {
            case "name":
                setValidity((prevValidity) => ({
                    ...prevValidity,
                    name: validator.isLength(value, {
                        min: 2
                    })
                }));
                setContact((prevContact) => ({
                    ...prevContact,
                    name: value
                }));
                break;
            case "phone":
                setValidity((prevValidity) => ({
                    ...prevValidity,
                    phone: validator.isMobilePhone(value)
                }));
                setContact((prevContact) => ({
                    ...prevContact,
                    phone: normalizeInput(value)
                }));
                break;
            case "email":
                setValidity((prevValidity) => ({
                    ...prevValidity,
                    email: validator.isEmail(value)
                }));
                setContact((prevContact) => ({
                    ...prevContact,
                    email: value
                }));
                break;
            case "message":
                setValidity((prevValidity) => ({
                    ...prevValidity,
                    message: validator.isLength(value, {
                        min: 5,
                        max: 255
                    })
                }));
                setContact((prevContact) => ({
                    ...prevContact,
                    message: value
                }));
                break;
            default:
                break;
        }
    };
    const inputs = [
        { name: "name", placeholder: "Your Name...", type: "text" },
        { name: "phone", placeholder: "Your Phone Number...", type: "text" },
        { name: "email", placeholder: "Your Email Address...", type: "email" },
        { name: "message", placeholder: "Detailed message explaining the project...", as: "textarea" }
    ];
    console.log(validity);
    const isDisabled = () => {
        return !(validity.name === true && validity.email === true && validity.phone === true && validity.message === true);
    };
    return (
        <Form>
            <p>Thank you for considering me for your upcoming project. I lead a dynamic and fast-paced lifestyle, but I am genuinely excited about potential collaborations. If you have a project in mind that you believe aligns with my style and brand, please reach out to me via the contact details provided below.</p>
            <p>While my schedule is often full, I am committed to exploring exciting opportunities and making time for projects that resonate with my vision. Your interest is highly valued, and I look forward to discussing how we can work together to create something exceptional.</p>
            <p>Please fill out the form below with as much detail as possible, and I will make every effort to accommodate your request within the constraints of my schedule. Let's make magic happen!</p>

            <Form.Group>
                {inputs.map((input) => {
                    return <Form.Control name={input.name} type={input.type} placeholder={input.placeholder} className={validity[input.name] ? "ok" : "error"} onChange={handleChange} value={contact[input.name]} as={input.as} />;
                })}
            </Form.Group>
            <Button disabled={isDisabled()}>Send Booking Request</Button>
        </Form>
    );
};

ContactForm.propTypes = {};

export default ContactForm;
