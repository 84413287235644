import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router";
import { mdiShieldCrown } from "@mdi/js/mdi";
import Icon from "@mdi/react";
import { useAuth } from "../../../AuthContext";
import useAPI from "../../../helpers/apiHelper";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";

const ImageRotator = (props) => {
    const navigate = useNavigate();
    const { isLoggedIn } = useAuth();
    const { get } = useAPI();
    const [photos, setPhotos] = useState([]);
    const [settings, setSettings] = useState({});
    const [currentImage, setCurrentImage] = useState(0);
    const [rotationInterval, setRotationInterval] = useState(null);

    useEffect(() => {
        if (props.settings) {
            setSettings(props.settings);
        } else {
            get("/api/settings").then((res) => {
                setSettings(res.data);
            });
        }
        get("/api/photos").then((res) => {
            setPhotos(res.data);
        });
        // eslint-disable-next-line
    }, [props.settings]);
    useEffect(() => {
        if (settings.rotation_speed) {
            startRotating();
        }
        return () => {
            clearInterval(rotationInterval);
        };
    }, [settings, photos]);

    const startRotating = () => {
        if (rotationInterval) {
            clearInterval(rotationInterval);
        }
        setRotationInterval(
            setInterval(() => {
                setCurrentImage((prevImage) => (prevImage + 1) % photos.length || 0);
            }, settings.rotation_speed)
        );
    };

    const handleTileClick = (index) => {
        setCurrentImage(index);
        startRotating();
    };
    const handleImageLoaded = (e) => {
        e.target.parentNode.style.animationDelay = `${e.target.dataset.index * settings.tile_load_delay}s`;
    };
    return Object.keys(settings).length > 0 && photos.length > 0 ? (
        <div className="image-rotation">
            {isLoggedIn() ? (
                <div className="actions">
                    <Icon path={mdiShieldCrown} onClick={() => navigate("/admin")} />
                </div>
            ) : null}
            <SimpleBar className="slider d-none d-md-block">
                {photos.map((photo, i) => {
                    return (
                        <div className={`tile${currentImage === i ? " active" : ""}`} key={i} onClick={() => handleTileClick(i)}>
                            <img className="img" data-index={i} src={`${photo.url}/96/96`} alt={photo._id} onLoad={handleImageLoaded} />
                        </div>
                    );
                })}
                <div className="gradient"></div>
            </SimpleBar>
            <div className="display">
                {photos.map((photo, i) => {
                    const { saturation, transition_speed } = settings;
                    const style = {
                        filter: `grayscale(${saturation})`,
                        transitionDuration: `${transition_speed / 1000}s`,
                        backgroundImage: `url(${photo.url})`,
                        opacity: i === currentImage ? 1 : 0
                    };
                    return <div key={i} className="img" style={style}></div>;
                })}
            </div>
        </div>
    ) : null;
};

ImageRotator.propTypes = {
    photos: PropTypes.arrayOf(PropTypes.object)
};

export default ImageRotator;
