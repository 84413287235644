import "./css/app.css";
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Homepage from "./pages/homepage/index";
import NotFound from "./components/NotFound";
import PhotosAdd from "./pages/photos/add";
import PhotosIndex from "./pages/photos/index";
import LoginPage from "./pages/authentication/login";
import RegisterPage from "./pages/authentication/register";
import FrontendLayout from "./layouts/frontend";
import BackendLayout from "./layouts/backend";
import SettingsIndex from "./pages/settings";
import { useAuth } from "./AuthContext";
const App = () => {
    const { isLoggedIn } = useAuth();
    const AdminRoutes = () => {
        return isLoggedIn() ? (
            <Routes>
                <Route index element={<PhotosIndex />} />
                <Route path="upload" element={<PhotosAdd />} />
                <Route path="settings" element={<SettingsIndex />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        ) : (
            <Navigate to="/login" replace />
        );
    };
    return (
        <Routes>
            <Route
                path="/"
                element={
                    <FrontendLayout>
                        <Homepage />
                    </FrontendLayout>
                }
            />
            <Route path="register" element={<RegisterPage />} />
            <Route path="login" element={<LoginPage />} />
            <Route
                path="/admin/*"
                element={
                    <BackendLayout>
                        <AdminRoutes />
                    </BackendLayout>
                }
            />
        </Routes>
    );
};
export default App;
