import axios from "axios";
import { useAuth } from "../AuthContext";
import { useEffect, useState } from "react";

const useAPI = () => {
    const { authInfo } = useAuth();
    const [config, setConfig] = useState({});

    useEffect(() => {
        if (authInfo?.access_token) {
            setConfig({
                headers: {
                    "X-From-Site": "https://carlymarie.net",
                    Authorization: `Bearer ${authInfo.access_token}`
                }
            });
        } else {
            setConfig({
                headers: {
                    "X-From-Site": "https://carlymarie.net"
                }
            });
        }
    }, [authInfo]);

    const _host = "https://carlymarie.net";
    const get = (path) => axios.get(`${_host}${path}`, config);
    const postWithProgress = (path, data, onUploadProgress) => {
        const _config = { ...config, onUploadProgress };
        return axios.post(`${_host}${path}`, data, _config);
    };
    const patch = (path, data) => axios.patch(`${_host}${path}`, data, config);
    const post = (path, data) => axios.post(`${_host}${path}`, data, config);
    const put = (path, data) => axios.put(`${_host}${path}`, data, config);
    const remove = (path) => axios.delete(`${_host}${path}`, config);

    return { get, post, patch, put, remove, postWithProgress };
};
export default useAPI;
