// authContext.js
import { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [authInfo, setAuthInfo] = useState(null);
    const navigate = useNavigate();

    const login = (user) => {
        console.log(user);
        setAuthInfo(user);
    };
    const logout = () => {
        setAuthInfo(null);
        navigate("/login");
    };

    const isLoggedIn = () => {
        return authInfo !== null;
    };

    return <AuthContext.Provider value={{ authInfo, login, logout, isLoggedIn }}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
    return useContext(AuthContext);
};
