import React, { useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiKey, mdiEmail, mdiAccount, mdiCamera } from "@mdi/js";
import API from "../../helpers/apiHelper";
import { useAuth } from "../../AuthContext";

const RegisterPage = (props) => {
    const { login } = useAuth();
    const { post } = API();
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [password, setPassword] = useState("");
    const [avatar, setAvatar] = useState("");
    const handleLogin = (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append("avatar", avatar);
        formData.append("name", name);
        formData.append("email", email);
        formData.append("password", password);
        post("/api/users", formData)
            .then((res) => {
                login(res.data);
                navigate("/admin");
            })
            .catch((err) => {
                const { status } = err.response;
                switch (status) {
                    case 404:
                        alert("Invalid Login Credentials");
                        break;
                    default:
                        break;
                }
            });
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        switch (name) {
            case "name":
                setName(value);
                break;
            case "avatar":
                setAvatar(e.target.files[0]);
                break;
            case "email":
                setEmail(value);
                break;
            case "password":
                setPassword(value);
                break;
            default:
                break;
        }
    };
    return (
        <div id="login">
            <div className="wrapper">
                <h1>Admin Login</h1>
                <Form onSubmit={handleLogin}>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1">
                            <Icon path={mdiCamera} size={1} color="white" />
                        </InputGroup.Text>
                        <Form.Control type="file" name="avatar" onChange={handleChange} />
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1">
                            <Icon path={mdiAccount} size={1} color="white" />
                        </InputGroup.Text>
                        <Form.Control type="text" name="name" placeholder="Your Name" value={name} onChange={handleChange} />
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1">
                            <Icon path={mdiEmail} size={1} color="white" />
                        </InputGroup.Text>
                        <Form.Control type="email" name="email" placeholder="Email" value={email} onChange={handleChange} />
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1">
                            <Icon path={mdiKey} size={1} color="white" />
                        </InputGroup.Text>
                        <Form.Control type="password" name="password" placeholder="Password" value={password} onChange={handleChange} />
                    </InputGroup>
                    <Button type="submit">Login</Button>
                </Form>
            </div>
        </div>
    );
};

RegisterPage.propTypes = {};

export default RegisterPage;
