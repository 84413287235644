import React, { useEffect, useState } from "react";
import { Container, Col, Row, Form, Button } from "react-bootstrap";
import ImageRotator from "../homepage/components/ImageRotator";
import { Badge } from "react-bootstrap/esm";
import useAPI from "../../helpers/apiHelper";

const SettingsIndex = (props) => {
    const [photos, setPhotos] = useState([]);
    const { get, post } = useAPI();
    const [settings, setSettings] = useState({});
    const handleChange = (e) => {
        switch (e.target.name) {
            case "rotationSpeed":
                setSettings((prevSettings) => ({
                    ...prevSettings,
                    rotation_speed: e.target.value
                }));
                break;
            case "tileLoadDelay":
                setSettings((prevSettings) => ({
                    ...prevSettings,
                    tile_load_delay: e.target.value
                }));
                break;
            case "fadeSpeed":
                setSettings((prevSettings) => ({
                    ...prevSettings,
                    transition_speed: e.target.value
                }));
                break;
            case "saturation":
                setSettings((prevSettings) => ({
                    ...prevSettings,
                    saturation: e.target.value
                }));
                break;
            case "online":
                setSettings((prevSettings) => ({
                    ...prevSettings,
                    online: e.target.checked
                }));
                break;
            default:
                break;
        }
    };
    useEffect(() => {
        get("/api/settings").then((res) => {
            setSettings(res.data);
        });
        get("/api/photos").then((res) => {
            setPhotos(res.data);
        });
        // eslint-disable-next-line
    }, []);
    const handleSave = (e) => {
        e.preventDefault();
        post("/api/settings", settings)
            .then(() => {})
            .catch((err) => {
                alert("ERROR");
                console.log(err);
            });
    };
    return (
        <Container style={{ marginTop: 50 }}>
            <Row>
                <Col md={8}>
                    <Form>
                        <Form.Group>
                            <Form.Label>
                                Image Pause Duration <Badge>{settings.rotation_speed / 1000} Seconds</Badge>
                            </Form.Label>
                            <Form.Control type="range" value={settings.rotation_speed} min={1000} max={60000} step={1000} name="rotationSpeed" onChange={handleChange} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                Image Fade Duration <Badge>{settings.transition_speed / 1000} Seconds</Badge>
                            </Form.Label>
                            <Form.Control type="range" value={settings.transition_speed} min={100} max={5000} step={100} name="fadeSpeed" onChange={handleChange} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                Image Grayscale <Badge>{Math.round(settings.saturation * 100)}%</Badge>
                            </Form.Label>
                            <Form.Control type="range" value={settings.saturation} min={0} max={1} step={0.01} name="saturation" onChange={handleChange} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                Tile Load Delay <Badge>{settings.tile_load_delay}s</Badge>
                            </Form.Label>
                            <Form.Control type="range" value={settings.tile_load_delay} min={0} max={1} step={0.01} name="tileLoadDelay" onChange={handleChange} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Check type="checkbox" name="online" onChange={handleChange} checked={settings.online} label={"Make website public"} />
                        </Form.Group>
                        <Button variant="primary" type="submit" onClick={handleSave}>
                            Save
                        </Button>
                    </Form>
                </Col>
                <Col md={4} style={{ height: 640 }}>
                    {photos.length > 0 ? <ImageRotator key={settings.tile_load_delay} photos={photos} settings={settings} /> : null}
                </Col>
            </Row>
        </Container>
    );
};

export default SettingsIndex;
