import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { mdiLoading } from "@mdi/js";
import Icon from "@mdi/react";

const ImageLoader = (props) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const imgRef = useRef();
    useEffect(() => {
        // const img = new Image();
        // img.src = props.image.url;
        // img.onload = () => {
        //     setIsLoaded(true);
        // };
        // // Optional: Handle image load errors
        // img.onerror = () => {
        //     console.error("Error loading image");
        //     setIsLoaded(true); // Set to true to prevent infinite loading
        // };
    }, [props.image.url]);

    const containerStyle = {
        backgroundImage: `url(${props.image.url})`
    };

    return (
        <div
            ref={imgRef}
            className="img"
            id={props.image._id}
            onMouseDown={(e) => {
                imgRef.current.style.filter = "grayscale(0)";
            }}
            onMouseUp={(e) => {
                imgRef.current.style.filter = "grayscale(1)";
            }}
        >
            <img
                src={`${props.image.url}/254/388`}
                onLoad={(e) => {
                    setIsLoaded(true);
                }}
                className={isLoaded ? "loaded" : ""}
            />
            {props.children}
            {!isLoaded ? <Icon path={mdiLoading} spin className="loader" /> : null}
        </div>
    );
};

ImageLoader.propTypes = { image: PropTypes.object };

export default ImageLoader;
